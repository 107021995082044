import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Hidden, Drawer as MUIDrawer, IconButton, Divider, Toolbar, Typography, Avatar } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import DrawerContent from 'DrawerContent';

const drawerWidth = 240;

const styles = ( theme ) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth + 'px'
    }
  },
  paper: {
    width: drawerWidth + 'px'
  },
  drawer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  toolbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  title: {
    flexGrow: 1
  }
});

class Drawer extends React.Component {

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.props.drawerVisible !== nextProps.drawerVisible ||
      this.props.activeRoute !== nextProps.activeRoute ||
      Object.entries(this.props.routeEvents).sort().toString() !== Object.entries(nextProps.routeEvents).sort().toString() ||
      this.props.channels.toString() !== nextProps.channels.toString()
    );
  }

  render () {
    return(
      <nav
        className={this.props.classes.root}
      >
        <Hidden
          mdUp
          implementation="js"
        >
          <MUIDrawer
            variant="temporary"
            anchor="left"
            open={this.props.drawerVisible}
            onClose={this.props.hideDrawer}
            classes={{
              paper: this.props.classes.paper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            <div
              className={this.props.classes.drawer}
            >
              <Toolbar
                className={this.props.classes.toolbar}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="Close drawer"
                  onClick={this.props.hideDrawer}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  noWrap={true}
                  className={this.props.classes.title}
                >
                  CHATCRYPT
                </Typography>
                <Avatar
                  alt="ChatCrypt"
                  variant="square"
                  src={process.env.PUBLIC_URL + '/logo-white.png'}
                />
              </Toolbar>
              <Divider />
              <DrawerContent
                theme={this.props.theme}
                activeRoute={this.props.activeRoute}
                setActiveRoute={this.props.setActiveRoute}
                routeEvents={this.props.routeEvents}
                channels={this.props.channels}
              />
            </div>
          </MUIDrawer>
        </Hidden>
        <Hidden
          smDown
          implementation="js"
        >
          <MUIDrawer
            variant="permanent"
            anchor="left"
            open={true}
            classes={{
              paper: this.props.classes.paper
            }}
          >
            <div
              className={this.props.classes.drawer}
            >
              <Toolbar
                className={this.props.classes.toolbar}
              >
                <Typography
                  variant="h6"
                  noWrap={true}
                  className={this.props.classes.title}
                >
                  CHATCRYPT
                </Typography>
                <Avatar
                  alt="ChatCrypt"
                  variant="square"
                  src={process.env.PUBLIC_URL + '/logo-white.png'}
                />
              </Toolbar>
              <Divider />
              <DrawerContent
                theme={this.props.theme}
                activeRoute={this.props.activeRoute}
                setActiveRoute={this.props.setActiveRoute}
                routeEvents={this.props.routeEvents}
                channels={this.props.channels}
              />
            </div>
          </MUIDrawer>
        </Hidden>
      </nav>
    );
  }

}

Drawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Drawer);
