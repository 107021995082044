import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, Typography } from '@material-ui/core';

const styles = ( theme ) => ({
  text: {
    marginTop: theme.spacing(0.33),
    marginBottom: theme.spacing(0.33)
  },
  inline: {
    display: 'inline',
    userSelect: 'text'
  }
});

class ChannelListItem extends React.Component {

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.index !== nextProps.index ||
      this.props.first !== nextProps.first ||
      this.props.last !== nextProps.last
    );
  }

  render () {

    const itemStyle = {
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
      paddingTop: '0px',
      paddingBottom: '0px'
    };

    if (
      this.props.first
    ) {
      delete(itemStyle.paddingTop);
    }

    if (
      this.props.last
    ) {
      delete(itemStyle.paddingBottom);
    }

    return(
      <ListItem
        style={itemStyle}
      >
        {
          this.props.item.type === 'text'
          ? <ListItemText
              className={this.props.classes.text}
              primary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={this.props.classes.inline}
                    color="primary"
                  >
                    @{this.props.item.username}
                  </Typography>
                  <Typography
                    component="span"
                    variant="caption"
                    className={this.props.classes.inline}
                    color="textSecondary"
                  >
                    {' ' + this.props.item.note}
                  </Typography>
                </>
              }
              secondary={
                <Typography
                  component="span"
                  variant="body1"
                  className={this.props.classes.inline}
                  color="textPrimary"
                >
                  {this.props.item.data}
                </Typography>
              }
            />
          : <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={this.props.classes.inline}
                    color="primary"
                  >
                    {this.props.item.data}
                  </Typography>
                  <Typography
                    component="span"
                    variant="caption"
                    className={this.props.classes.inline}
                    color="textSecondary"
                  >
                    {' ' + this.props.item.note}
                  </Typography>
                </>
              }
            />
        }
      </ListItem>
    );

  }

}

ChannelListItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChannelListItem);
