import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChannelList from 'ChannelList';
import ChannelForm from 'ChannelForm';
import ChannelSecured from 'ChannelSecured';
import ChannelClients from 'ChannelClients';
import ChatCrypt from 'ChatCrypt';

const styles = ( theme ) => ({
  root: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }
});

class Channel extends React.Component {

  constructor ( props ) {

    super(props);

    this.state = {
      secured: false,
      messageListUpdated: 0,
      clientListUpdated: 0,
      clientsVisible: false
    };

    this.debug = false;

    this.mounted = false;
    this.chat = null;

    this.messageList = [];
    this.clientList = [];

    this.channelListRef = React.createRef();

    this.showClients = this.showClients.bind(this);
    this.hideClients = this.hideClients.bind(this);
    this.sendTextMessage = this.sendTextMessage.bind(this);
    this.messageDate = this.messageDate.bind(this);

    this.onServerClosed = this.onServerClosed.bind(this);
    this.onServerSecured = this.onServerSecured.bind(this);
    this.onClientSecured = this.onClientSecured.bind(this);
    this.onClientList = this.onClientList.bind(this);
    this.onClientMessage = this.onClientMessage.bind(this);

  }

  shouldComponentUpdate ( nextProps, nextState ) {
    return(
      this.props.visible !== nextProps.visible ||
      this.props.theme !== nextProps.theme ||
      this.state.secured !== nextState.secured ||
      this.state.messageListUpdated !== nextState.messageListUpdated ||
      this.state.clientListUpdated !== nextState.clientListUpdated ||
      this.state.clientsVisible !== nextState.clientsVisible ||
      this.props.settingsSend !== nextProps.settingsSend
    );
  }

  componentDidMount () {

    this.mounted = true;

    try {

      // Initialize chat provider

      this.chat = new ChatCrypt({
        rsaPublic: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqH4tpxdB9Racf1tbssYU9KeiyMjPRnaZAJH+LEb+1RfRoiC+7HCSex6iHviCnpZWjKJbePm5WubCvdLu7L7AxFevN1xCq6Y6XfAb3HodapaZM+omcVQWw/bhhfNDGNGXK8eEUUgqoh1i0EJqgx9GrwIz/zYBGc9BvOYfd1LtrfOcXnibWSL91Y7mbkVtW9EiGoGaYwYQclCod4vYOsCdYIVYnVZP8MrRmE0FGhc/XjBdq8Vk2pXDLBFH7faGF0Hn6TgTiapJQ99KX3LnujBkjE1jmETgnMUT2C0R33UUfGxQ8U1rYolNW57L5CeoHf/7JKa5JtadaprO6pf5ruodlQIDAQAB',
        wsAddress: 'wss://client.chatcrypt.com/server/',
        debug: this.debug
      }, {
        onServerClosed: this.onServerClosed,
        onServerSecured: this.onServerSecured,
        onClientSecured: this.onClientSecured,
        onClientList: this.onClientList,
        onClientMessage: this.onClientMessage
      });

      // Set credentials

      this.chat.setCredentials(this.props.channel.username, this.props.channel.channel, this.props.channel.password);

      // Connect to server

      this.chat.connect();

    } catch ( error ) {
      if (
        this.debug
      ) {
        console.log('componentDidMount', error);
      }
    }

  }

  componentWillUnmount () {

    this.mounted = false;
    this.messageList = [];
    this.clientList = [];

    try {

      // Destruct chat provider

      this.chat.destruct();

    } catch ( error ) {
      if (
        this.debug
      ) {
        console.log('componentWillUnmount', error);
      }
    }

    // Remove chat provider

    this.chat = null;

  }

  // Send text message

  sendTextMessage ( data ) {
    if (
      this.mounted
    ) {

      let sent = false;

      try {
        sent = this.chat.sendChannelMessage('t', data);
      } catch ( error ) {
        if (
          this.debug
        ) {
          console.log('sendTextMessage', error);
        }
      }

      if (
        this.mounted
      ) {

        this.messageList.push({
          username: this.props.channel.username,
          data: data,
          type: 'text',
          note: ( sent ? this.messageDate() : 'Failed' )
        });

        this.setState(( state ) => ({
          messageListUpdated: ( state.messageListUpdated + 1 )
        }));

      }

    }
  }

  // Message date

  messageDate () {

    const date = new Date();

    return(
      ( '0' + date.getHours() ).slice(-2) + ':' +
      ( '0' + date.getMinutes() ).slice(-2)
    );

  }

  // Server closed callback
  // Parameters: nothing

  onServerClosed () {
    if (
      this.mounted
    ) {
      this.setState(( state ) => {

        if (
          state.secured === false
        ) {
          return({});
        }

        for (
          const client of this.clientList
        ) {
          this.messageList.push({
            data: 'Secured a connection with @' + client.username,
            type: 'notice',
            note: this.messageDate()
          });
        }

        this.messageList.push({
          data: 'Server connection closed',
          type: 'notice',
          note: this.messageDate()
        });

        this.clientList = [];

        return({
          secured: false,
          messageListUpdated: ( state.messageListUpdated + 1 ),
          clientListUpdated: ( state.clientListUpdated + 1 )
        });

      });
    }
  }

  // Server secured callback
  // Parameters: nothing

  onServerSecured () {
    if (
      this.mounted
    ) {

      this.messageList.push({
        data: 'Server connection secured',
        type: 'notice',
        note: this.messageDate()
      });

      this.setState(( state ) => ({
        secured: true,
        messageListUpdated: ( state.messageListUpdated + 1 )
      }));

    }
  }

  // Client secured callback
  // Parameters: client => { clientId: <client id>, username: <username> }

  onClientSecured ( client ) {
    if (
      this.mounted
    ) {

      this.messageList.push({
        data: 'Secured a connection with @' + client.username,
        type: 'notice',
        note: this.messageDate()
      });

      this.clientList.push({
        clientId: client.clientId,
        username: client.username
      });

      this.setState(( state ) => ({
        messageListUpdated: ( state.messageListUpdated + 1 ),
        clientListUpdated: ( state.clientListUpdated + 1 )
      }));

    }
  }

  // Client list callback
  // Parameters: clients => [ { clientId: <client id>, username: <username> } ]

  onClientList ( clients ) {
    if (
      this.mounted
    ) {
      this.setState(( state ) => {

        for (
          const client of this.clientList
        ) {
          if (
            ! clients.find(( item ) => item.clientId === client.clientId)
          ) {
            this.messageList.push({
              data: 'Connection closed with @' + client.username,
              type: 'notice',
              note: this.messageDate()
            });
          }
        }

        this.clientList = clients;

        return({
          messageListUpdated: ( state.messageListUpdated + 1 ),
          clientListUpdated: ( state.clientListUpdated + 1 )
        });

      });
    }
  }

  // Client message callback
  // Parameters: message => { clientId: <client id>, username: <username>, type: <type>, data: <data> }

  onClientMessage ( message ) {
    if (
      this.mounted &&
      message.type === 't'
    ) {

      this.messageList.push({
        username: message.username,
        data: message.data,
        type: 'text',
        note: this.messageDate()
      });

      this.setState(( state ) => ({
        messageListUpdated: ( state.messageListUpdated + 1 )
      }));

      this.props.addRouteEvent(this.props.channel.route);

    }
  }

  // Show clients

  showClients () {
    this.setState({
      clientsVisible: true
    });
  }

  // Hide clients

  hideClients () {
    this.setState({
      clientsVisible: false
    });
  }

  render () {
    return(
      <div
        className={this.props.classes.root}
        style={{
          display: this.props.visible ? 'flex' : 'none'
        }}
      >
        <ChannelList
          ref={this.channelListRef}
          theme={this.props.theme}
          messageList={this.messageList}
          messageListUpdated={this.state.messageListUpdated}
        />
        <ChannelForm
          theme={this.props.theme}
          secured={this.state.secured}
          sendTextMessage={this.sendTextMessage}
          settingsSend={this.props.settingsSend}
        />
        <ChannelSecured
          theme={this.props.theme}
          secured={this.state.secured}
        />
        <ChannelClients
          theme={this.props.theme}
          clientsVisible={this.state.clientsVisible}
          hideClients={this.hideClients}
          clientList={[ { clientId: 'me', username: this.props.channel.username }, ...this.clientList ]}
          clientListUpdated={this.state.clientListUpdated}
        />
      </div>
    );
  }

}

Channel.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Channel);
