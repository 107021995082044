import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

const styles = ( theme ) => ({});

class ChannelClientsList extends React.Component {

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.props.clientListUpdated !== nextProps.clientListUpdated
    );
  }

  render () {
    return(
      <List
        dense={true}
      >
        {
          this.props.clientList.map(( item ) =>
            <ListItem
              key={item.clientId}
              style={{
                wordBreak: 'break-word'
              }}
            >
              <ListItemText
                primary={'@' + item.username}
              />
            </ListItem>
          )
        }
      </List>
    );
  }

}

ChannelClientsList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChannelClientsList);
