import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar as MUIAppBar, Toolbar, IconButton, Typography, Badge } from '@material-ui/core';
import { Menu as MenuIcon, Close as CloseIcon, PeopleAlt as ClientsIcon } from '@material-ui/icons';

const drawerWidth = 240;

const styles = ( theme ) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth + 'px'
    }
  },
  menu: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  title: {
    flexGrow: 1
  }
});

class AppBar extends React.Component {

  constructor ( props ) {

    super(props);

    this.leaveChannel = this.leaveChannel.bind(this);
    this.showClients = this.showClients.bind(this);

  }

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.props.activeRoute !== nextProps.activeRoute ||
      this.props.routeEvent !== nextProps.routeEvent
    );
  }

  leaveChannel () {
    if (
      this.props.channel
    ) {
      this.props.leaveChannel(this.props.channel.route);
    }
  }

  showClients () {
    if (
      this.props.channel
    ) {
      try {
        this.props.channel.ref.current.showClients();
      } catch ( error ) {
        console.log(error);
      }
    }
  }

  render () {
    return(
      <MUIAppBar
        position="fixed"
        className={this.props.classes.root}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className={this.props.classes.menu}
            aria-label="Open drawer"
            onClick={this.props.showDrawer}
          >
            <Badge
              badgeContent={this.props.routeEvent}
              variant="dot"
              color="secondary"
            >
              <MenuIcon />
            </Badge>
          </IconButton>
          <Typography
            variant="h6"
            noWrap={true}
            className={this.props.classes.title}
          >
            {
              this.props.channel
              ? '#' + this.props.channel.channel
              : (
                this.props.activeRoute === 'enter'
                ? 'Enter a channel'
                : 'Settings'
              )
            }
          </Typography>
          {
            this.props.channel &&
              <>
                <IconButton
                  color="inherit"
                  aria-label="Leave channel"
                  onClick={this.leaveChannel}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="Show members"
                  onClick={this.showClients}
                >
                  <ClientsIcon />
                </IconButton>
              </>
          }
        </Toolbar>
      </MUIAppBar>
    );
  }

}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppBar);
