import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Security as ConnectionIcon } from '@material-ui/icons';

const styles = ( theme ) => ({});

const SlideTransition = ( props ) => {
  return(
    <Slide {...props} direction="up" />
  );
};

class ChannelSecured extends React.Component {

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.props.secured !== nextProps.secured
    );
  }

  render () {
    return(
      <Snackbar
        open={! this.props.secured}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        TransitionComponent={SlideTransition}
      >
        <Alert
          severity="info"
          icon={<ConnectionIcon fontSize="inherit" />}
        >
          Establishing secure connection...
        </Alert>
      </Snackbar>
    );
  }

}

ChannelSecured.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChannelSecured);
