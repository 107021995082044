import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import ChannelClientsList from 'ChannelClientsList';

const styles = ( theme ) => ({
  paper: {
    width: '80%',
    maxHeight: '435px'
  }
});

class ChannelClients extends React.Component {

  constructor ( props ) {

    super(props);

    this.hideClients = this.hideClients.bind(this);

  }

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.props.clientsVisible !== nextProps.clientsVisible ||
      this.props.clientListUpdated !== nextProps.clientListUpdated
    );
  }

  hideClients () {
    this.props.hideClients();
  }

  render () {
    return(
      <Dialog
        classes={{
          paper: this.props.classes.paper
        }}
        open={this.props.clientsVisible}
        maxWidth="xs"
        onClose={this.hideClients}
      >
        <DialogTitle>Members</DialogTitle>
        <DialogContent
          dividers={true}
        >
          <ChannelClientsList
            theme={this.props.theme}
            clientList={this.props.clientList}
            clientListUpdated={this.props.clientListUpdated}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus={true}
            color="primary"
            onClick={this.hideClients}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

ChannelClients.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChannelClients);
