import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import ReactGA from 'react-ga';
import AppBar from 'AppBar';
import Drawer from 'Drawer';
import Enter from 'Enter';
import Channel from 'Channel';
import Settings from 'Settings';

ReactGA.initialize('G-NJ69ZRYX6H');

const styles = ( theme ) => ({
  root: {
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    height: 'auto',
    display: 'flex',
    position: 'absolute',
    flexDirection: 'row'
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'stretch',
    flexDirection: 'column'
  }
});

class App extends React.Component {

  constructor ( props ) {

    super(props);

    this.state = {
      drawerVisible: false,
      activeRoute: 'enter',
      routeEvents: {},
      channels: [],
      settings: {
        send: true,
        notifications: false,
        sounds: false
      }
    };

    this.rootRef = React.createRef();

    this.viewportResizeUpdate = false;

    this.viewportResize = this.viewportResize.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.hideDrawer = this.hideDrawer.bind(this);
    this.setActiveRoute = this.setActiveRoute.bind(this);
    this.addRouteEvent = this.addRouteEvent.bind(this);
    this.enterChannel = this.enterChannel.bind(this);
    this.leaveChannel = this.leaveChannel.bind(this);
    this.toggleSettingsSend = this.toggleSettingsSend.bind(this);
    this.toggleSettingsNotifications = this.toggleSettingsNotifications.bind(this);
    this.toggleSettingsSounds = this.toggleSettingsSounds.bind(this);

    try {
      this.beep = new Audio(process.env.PUBLIC_URL + '/beep.wav');
    } catch ( error ) {
      console.log(error);
    }

  }

  shouldComponentUpdate ( nextProps, nextState ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.state.drawerVisible !== nextState.drawerVisible ||
      this.state.activeRoute !== nextState.activeRoute ||
      Object.entries(this.state.routeEvents).sort().toString() !== Object.entries(nextState.routeEvents).sort().toString() ||
      this.state.channels.toString() !== nextState.channels.toString() ||
      Object.entries(this.state.settings).sort().toString() !== Object.entries(nextState.settings).sort().toString()
    );
  }

  componentDidMount () {

    ReactGA.pageview(window.location.pathname + window.location.search);

    if (
      window.visualViewport &&
      requestAnimationFrame
    ) {
      window.visualViewport.addEventListener('resize', this.viewportResize);
    }

    this.setState(( state ) => ({
      settings: {
        send: (
          localStorage.getItem('settings-send')
          ? ( parseInt(localStorage.getItem('settings-send')) ? true : false )
          : state.settings.send
        ),
        notifications: (
          localStorage.getItem('settings-notifications')
          ? ( parseInt(localStorage.getItem('settings-notifications')) ? true : false )
          : state.settings.notifications
        ),
        sounds: (
          localStorage.getItem('settings-sounds')
          ? ( parseInt(localStorage.getItem('settings-sounds')) ? true : false )
          : state.settings.sounds
        )
      }
    }));

  }

  componentWillUnmount () {
    if (
      window.visualViewport &&
      requestAnimationFrame
    ) {
      window.visualViewport.removeEventListener('resize', this.viewportResize);
    }
  }

  viewportResize ( event ) {

    if (
      this.viewportResizeUpdate
    ) {
      return;
    }

    this.viewportResizeUpdate = true;

    requestAnimationFrame(() => {

      try {

        this.rootRef.current.style.bottom = 'auto';
        this.rootRef.current.style.height = event.target.height + 'px';
        this.rootRef.current.scrollIntoView();

        const channelIndex = this.state.channels.map(( item ) => item.route).indexOf(this.state.activeRoute);

        if (
          channelIndex > -1
        ) {
          this.state.channels[channelIndex].ref.current.channelListRef.current.scrollDown();
        }

      } catch ( error ) {
        console.log(error);
      }

      this.viewportResizeUpdate = false;

    });

  }

  showDrawer () {
    this.setState({
      drawerVisible: true
    });
  }

  hideDrawer () {
    this.setState({
      drawerVisible: false
    });
  }

  setActiveRoute ( route ) {
    this.setState(( state ) => ({
      drawerVisible: false,
      activeRoute: route,
      routeEvents: {
        ...state.routeEvents,
        [route]: 0
      }
    }));
  }

  addRouteEvent ( route ) {

    if (
      this.state.settings.notifications
    ) {

      const channelIndex = this.state.channels.map(( item ) => item.route).indexOf(route);

      if (
        channelIndex > -1
      ) {
        try {
          Notification.requestPermission(( result ) => {
            if (
              result === 'granted'
            ) {
              navigator.serviceWorker.ready.then(( registration ) => {
                registration.showNotification('ChatCrypt', {
                  body: 'New message at #' + this.state.channels[channelIndex].channel,
                  icon: process.env.PUBLIC_URL + '/logo512.png',
                  tag: 'chatcrypt-' + route,
                  renotify: true
                });
              });
            }
          });
        } catch ( error ) {
          console.log(error);
        }
      }

    }

    if (
      this.state.settings.sounds
    ) {
      try {
        this.beep.play();
      } catch ( error ) {
        console.log(error);
      }
    }

    if (
      route !== this.state.activeRoute
    ) {
      this.setState(( state ) => ({
        routeEvents: {
          ...state.routeEvents,
          [route]: ( state.routeEvents[route] ? ( state.routeEvents[route] + 1 ) : 1 )
        }
      }));
    }

  }

  enterChannel ( username, channel, password ) {

    const route = 'channel-' + Buffer.from(channel, 'utf8').toString('hex');

    if (
      this.state.channels.map(( item ) => item.route).indexOf(route) > -1
    ) {
      return(false);
    }

    this.setState(( state ) => ({
      activeRoute: route,
      channels: [
        ...state.channels,
        {
          ref: React.createRef(),
          route: route,
          channel: channel,
          username: username,
          password: password
        }
      ]
    }));

    return(true);

  }

  leaveChannel ( route ) {
    if (
      this.state.channels.map(( item ) => item.route).indexOf(route) > -1
    ) {
      this.setState(( state ) => ({
        activeRoute: 'enter',
        channels: state.channels.filter(( item ) => item.route !== route)
      }));
    }
  }

  toggleSettingsSend () {
    this.setState(( state ) => {

      localStorage.setItem('settings-send', ( state.settings.send ? 0 : 1 ));

      return({
        settings: {
          ...state.settings,
          send: ! state.settings.send
        }
      });

    });
  }

  toggleSettingsNotifications () {
    this.setState(( state ) => {

      localStorage.setItem('settings-notifications', ( state.settings.notifications ? 0 : 1 ));

      return({
        settings: {
          ...state.settings,
          notifications: ! state.settings.notifications
        }
      });

    });
  }

  toggleSettingsSounds () {
    this.setState(( state ) => {

      localStorage.setItem('settings-sounds', ( state.settings.sounds ? 0 : 1 ));

      return({
        settings: {
          ...state.settings,
          sounds: ! state.settings.sounds
        }
      });

    });
  }

  render () {
    return(
      <div
        ref={this.rootRef}
        className={this.props.classes.root}
      >
        <AppBar
          theme={this.props.theme}
          showDrawer={this.showDrawer}
          activeRoute={this.state.activeRoute}
          routeEvent={Object.values(this.state.routeEvents).reduce(( a, b ) => a + b, 0) > 0 ? 1 : 0}
          channel={
            this.state.activeRoute.match(/^channel/)
            ? this.state.channels[this.state.channels.map(( item ) => item.route).indexOf(this.state.activeRoute)]
            : null
          }
          leaveChannel={this.leaveChannel}
        />
        <Drawer
          theme={this.props.theme}
          drawerVisible={this.state.drawerVisible}
          showDrawer={this.showDrawer}
          hideDrawer={this.hideDrawer}
          activeRoute={this.state.activeRoute}
          setActiveRoute={this.setActiveRoute}
          routeEvents={this.state.routeEvents}
          channels={this.state.channels}
        />
        <main
          className={this.props.classes.main}
        >
          <Toolbar />
          <Enter
            visible={this.state.activeRoute === 'enter'}
            theme={this.props.theme}
            channels={this.state.channels.map(( item ) => item.channel)}
            enterChannel={this.enterChannel}
          />
          {
            this.state.channels.map(( item ) => 
              <Channel
                ref={item.ref}
                visible={this.state.activeRoute === item.route}
                theme={this.props.theme}
                key={item.route}
                channel={item}
                addRouteEvent={this.addRouteEvent}
                settingsSend={this.state.settings.send}
              />
            )
          }
          <Settings
            visible={this.state.activeRoute === 'settings'}
            theme={this.props.theme}
            setTheme={this.props.setTheme}
            settings={this.state.settings}
            toggleSettingsSend={this.toggleSettingsSend}
            toggleSettingsNotifications={this.toggleSettingsNotifications}
            toggleSettingsSounds={this.toggleSettingsSounds}
          />
        </main>
      </div>
    );
  }

}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(App);
