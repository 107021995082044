import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container, Paper, TextField, Button } from '@material-ui/core';
import { Input as EnterIcon } from '@material-ui/icons';
import EnterDescription from 'EnterDescription';

const styles = ( theme ) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  input: {
    [`& fieldset`]: {
      borderRadius: '0px'
    },
    paddingBottom: theme.spacing(2)
  },
  button: {
    borderRadius: '0px'
  }
});

class Enter extends React.Component {

  constructor ( props ) {

    super(props);

    this.state = {
      username: '',
      channel: '',
      password: ''
    };

    this.updateUsername = this.updateUsername.bind(this);
    this.updateChannel = this.updateChannel.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.enterChannel = this.enterChannel.bind(this);

  }

  shouldComponentUpdate ( nextProps, nextState ) {
    return(
      this.props.visible !== nextProps.visible ||
      this.props.theme !== nextProps.theme ||
      this.state.username !== nextState.username ||
      this.state.channel !== nextState.channel ||
      this.state.password !== nextState.password
    );
  }

  updateUsername ( event ) {
    this.setState({
      username: event.target.value
    });
  }

  updateChannel ( event ) {
    this.setState({
      channel: event.target.value
    });
  }

  updatePassword ( event ) {
    this.setState({
      password: event.target.value
    });
  }

  handleKeyDown ( event ) {
    if (
      event.keyCode === 13 &&
      ! event.shiftKey
    ) {
      event.preventDefault();
      this.enterChannel();
    }
  }

  enterChannel () {
    if (
      this.state.username.match(/\S/) &&
      this.state.channel.match(/\S/) &&
      this.state.password.match(/\S/) &&
      this.props.channels.indexOf(this.state.channel.replace(/^\s+/, '').replace(/\s+$/, '')) < 0 &&
      this.props.enterChannel(
        this.state.username.replace(/^\s+/, '').replace(/\s+$/, ''),
        this.state.channel.replace(/^\s+/, '').replace(/\s+$/, ''),
        this.state.password.replace(/^\s+/, '').replace(/\s+$/, '')
      )
    ) {
      this.setState({
        username: '',
        channel: '',
        password: '',
      });
    }
  }

  render () {
    return(
      <div
        className={this.props.classes.root}
        style={{
          display: this.props.visible ? 'flex' : 'none'
        }}
      >
        <Container
          disableGutters={true}
          maxWidth="sm"
        >
          <Paper
            className={this.props.classes.paper}
            elevation={1}
          >
            <TextField
              className={this.props.classes.input}
              id="username"
              fullWidth={true}
              size="medium"
              variant="outlined"
              label="Username"
              inputProps={{
                autoComplete: 'off',
                autoCorrect: 'off',
                autoCapitalize: 'off',
                spellCheck: 'false',
                maxLength: 64
              }}
              error={this.state.username.length > 0 && ! this.state.username.match(/\S/)}
              value={this.state.username}
              onChange={this.updateUsername}
            />
            <TextField
              className={this.props.classes.input}
              id="channel"
              fullWidth={true}
              size="medium"
              variant="outlined"
              label="Channel"
              inputProps={{
                autoComplete: 'off',
                autoCorrect: 'off',
                autoCapitalize: 'off',
                spellCheck: 'false'
              }}
              error={
                this.state.channel.length > 0 && (
                  ! this.state.channel.match(/\S/) ||
                  this.props.channels.indexOf(this.state.channel.replace(/^\s+/, '').replace(/\s+$/, '')) > -1
                )
              }
              value={this.state.channel}
              onChange={this.updateChannel}
            />
            <TextField
              className={this.props.classes.input}
              id="password"
              fullWidth={true}
              size="medium"
              variant="outlined"
              label="Password"
              type="password"
              inputProps={{
                autoComplete: 'one-time-code',
                autoCorrect: 'off',
                autoCapitalize: 'off',
                spellCheck: 'false'
              }}
              error={this.state.password.length > 0 && ! this.state.password.match(/\S/)}
              value={this.state.password}
              onChange={this.updatePassword}
              onKeyDown={this.handleKeyDown}
            />
            <Button
              className={this.props.classes.button}
              size="large"
              fullWidth={true}
              variant="contained"
              color="primary"
              endIcon={<EnterIcon />}
              disabled={
                ! this.state.username.match(/\S/) ||
                ! this.state.channel.match(/\S/) ||
                ! this.state.password.match(/\S/) ||
                this.props.channels.indexOf(this.state.channel.replace(/^\s+/, '').replace(/\s+$/, '')) > -1
              }
              aria-label="Enter"
              onClick={this.enterChannel}
            >
              ENTER
            </Button>
          </Paper>
          <EnterDescription
            theme={this.props.theme}
          />
        </Container>
      </div>
    );
  }

}

Enter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Enter);
