import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';

const styles = ( theme ) => ({
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  inline: {
    display: 'inline'
  }
});

class EnterDescription extends React.Component {

  shouldComponentUpdate ( nextProps, nextState ) {
    return(
      this.props.theme !== nextProps.theme
    );
  }

  render () {
    return(
      <div>
        <Typography
          className={this.props.classes.text}
          variant="h6"
          color="textPrimary"
        >
          USAGE
        </Typography>
        <Typography
          className={this.props.classes.text}
          variant="body1"
          color="primary"
        >
          Each field has to contain at least one readable character.
        </Typography>
        <Typography
          className={this.props.classes.text}
          variant="body1"
        >
          Pick a channel name and a secret password, then share it with those who you would like to chat with. These have to be the same for each member. Your username can be anything.
        </Typography>
        <Typography
          className={this.props.classes.text}
          variant="body2"
          color="textSecondary"
        >
          Note that none of the above details are stored anywhere, so you will have to remember them. Users entering a channel with the wrong password will not be prompted to enter the correct password, they will simply arrive into another channel - one which will be accessible with that particular wrong password.
        </Typography>
        <Typography
          className={this.props.classes.text}
          variant="body2"
          color="secondary"
        >
          Connection to the server is temporarily closed on mobile devices while the application runs in the background.
          {' '}
          <Typography
            className={this.props.classes.inline}
            component="span"
            variant="body2"
            color="textSecondary"
          >
            This is due to the power saving features of these devices and the nature of our solution, in which messages are not stored or queued for later transmission. You are participating in the conversation only while the application is in the foreground.
          </Typography>
        </Typography>
        <Typography
          className={this.props.classes.text}
          variant="body1"
          color="textPrimary"
        >
          Visit the main <Link
            href="https://www.chatcrypt.com/"
            rel="external noopener"
            target="_blank"
            color="primary"
          >website</Link> for more information.
        </Typography>
      </div>
    );
  }

}

EnterDescription.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EnterDescription);
