import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Virtuoso } from 'react-virtuoso';
import ChannelListItem from 'ChannelListItem';

const styles = ( theme ) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  }
});

class ChannelList extends React.Component {

  constructor ( props ) {

    super(props);

    this.listRef = React.createRef();
    this.scrollDownTimeout = null;

    this.scrollDown = this.scrollDown.bind(this);
    this.generateItem = this.generateItem.bind(this);

  }

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.props.messageListUpdated !== nextProps.messageListUpdated
    );
  }

  componentDidUpdate () {
    this.scrollDown();
  }

  componentWillUnmount () {
    clearTimeout(this.scrollDownTimeout);
  }

  scrollDown () {

    clearTimeout(this.scrollDownTimeout);

    this.scrollDownTimeout = setTimeout(() => {
      try {
        this.listRef.current.scrollToIndex({
          index: ( this.props.messageList.length - 1 ),
          align: 'end'
        });
      } catch ( error ) {
        console.log(error);
      }
    }, 50);

  }

  generateItem ( index ) {
    return(
      <ChannelListItem
        index={index}
        first={index === 0}
        last={index === ( this.props.messageList.length - 1 )}
        item={this.props.messageList[index]}
      />
    );
  }

  render () {
    return(
      <div
        className={this.props.classes.root}
      >
        <Virtuoso
          ref={this.listRef}
          totalCount={this.props.messageList.length}
          overscan={200}
          item={this.generateItem}
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </div>
    );
  }

}

ChannelList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChannelList);
