import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container, Paper, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Switch, Typography } from '@material-ui/core';
import { NightsStay as DarkIcon, KeyboardReturn as SendIcon, Notifications as NotificationsIcon, VolumeUp as SoundsIcon } from '@material-ui/icons';

const styles = ( theme ) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
});

class Settings extends React.Component {

  constructor ( props ) {

    super(props);

    this.toggleTheme = this.toggleTheme.bind(this);

  }

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.visible !== nextProps.visible ||
      this.props.theme !== nextProps.theme ||
      Object.entries(this.props.settings).sort().toString() !== Object.entries(nextProps.settings).sort().toString()
    );
  }

  componentDidUpdate ( prevProps ) {
    if (
      this.props.settings.notifications &&
      ! prevProps.settings.notifications
    ) {
      try {
        Notification.requestPermission();
      } catch ( error ) {
        console.log(error);
      }
    }
  }

  toggleTheme () {
    this.props.setTheme(
      this.props.theme === 'light'
      ? 'dark'
      : 'light'
    );
  }

  render () {
    return(
      <div
        className={this.props.classes.root}
        style={{
          display: this.props.visible ? 'flex' : 'none'
        }}
      >
        <Container
          disableGutters={true}
          maxWidth="sm"
        >
          <Paper
            className={this.props.classes.paper}
            elevation={1}
          >
            <List>
              <ListItem
                key="dark"
              >
                <ListItemIcon>
                  <DarkIcon />
                </ListItemIcon>
                <ListItemText
                  id="settings-dark"
                  primary="Dark mode"
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={this.toggleTheme}
                    checked={this.props.theme === 'dark'}
                    inputProps={{
                      'aria-labelledby': 'settings-dark'
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                key="send"
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText
                  id="settings-send"
                  primary="Enter is send"
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={this.props.toggleSettingsSend}
                    checked={this.props.settings.send}
                    inputProps={{
                      'aria-labelledby': 'settings-send'
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                key="notifications"
              >
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText
                  id="settings-notifications"
                  primary="Notifications"
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={this.props.toggleSettingsNotifications}
                    checked={this.props.settings.notifications}
                    inputProps={{
                      'aria-labelledby': 'settings-notifications'
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                key="sounds"
              >
                <ListItemIcon>
                  <SoundsIcon />
                </ListItemIcon>
                <ListItemText
                  id="settings-sounds"
                  primary="Sounds"
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={this.props.toggleSettingsSounds}
                    checked={this.props.settings.sounds}
                    inputProps={{
                      'aria-labelledby': 'settings-sounds'
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
          <div>
            <Typography
              className={this.props.classes.text}
              variant="body2"
              color="secondary"
            >
              Notifications and sounds are currently in an experimental stage, they probably won't work on outdated or Apple (iOS/macOS/Safari/etc) systems.
            </Typography>
          </div>
        </Container>
      </div>
    );
  }

}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Settings);
