import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createMuiTheme  } from '@material-ui/core/styles';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import 'fontsource-roboto';

class AppTheme extends React.Component {

  constructor ( props ) {

    super(props);

    this.state = {
      theme: 'light'
    };

    this.setTheme = this.setTheme.bind(this);

  }

  shouldComponentUpdate ( nextProps, nextState ) {
    return(
      this.state.theme !== nextState.theme
    );
  }

  componentDidMount () {
    this.setState(( state ) => ({
      theme: (
        localStorage.getItem('theme')
        ? ( localStorage.getItem('theme') === 'dark' ? 'dark' : 'light' )
        : state.theme
      )
    }));
  }

  setTheme ( theme ) {

    localStorage.setItem('theme', theme);

    this.setState({
      theme: theme
    });

  }

  render () {

    const green = {
      light: '#20c020',
      main: '#20a020',
      dark: '#20e020'
    };

    const theme = createMuiTheme({
      palette: (
        this.state.theme === 'dark'
        ? {
          type: 'dark',
          primary: {
            ...green,
            contrastText: '#ffffff'
          },
          secondary: green,
          error: green,
          warning: green,
          info: green,
          success: green
        }
        : {
          type: 'light'
        }
      ),
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '*': {
              'user-select': 'none',
              'scrollbar-width': 'none'
            },
            'input,textarea': {
              'user-select': 'auto'
            },
            '*:hover': {
              'scrollbar-width': 'thin'
            },
            '*::-webkit-scrollbar': {
              width: '6px',
              height: '6px'
            },
            '*::-webkit-scrollbar-thumb': {
              'background-color': 'rgba(0,0,0,0)'
            },
            '*:hover::-webkit-scrollbar-thumb': {
              'background-color': 'rgba(0,0,0,0.25)'
            }
          }
        }
      }
    });

    return(
      <ThemeProvider
        theme={theme}
      >
        <CssBaseline />
        <App
          theme={this.state.theme}
          setTheme={this.setTheme}
        />
        <Helmet>
          <meta
            name="theme-color"
            content={
              this.state.theme === 'dark'
              ? '#20a020'
              : '#3f51b5'
            }
          />
        </Helmet>
      </ThemeProvider>
    );

  }

}

ReactDOM.render(
  <React.StrictMode>
    <AppTheme />
  </React.StrictMode>,
  document.getElementById('root')
);

if (
  process.env.NODE_ENV === 'development'
) {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}
