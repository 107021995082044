import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';

const styles = ( theme ) => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  input: {
    [`& fieldset`]: {
      borderRadius: '0px'
    }
  },
  button: {
    borderRadius: '0px'
  }
});

class ChannelForm extends React.Component {

  constructor ( props ) {

    super(props);

    this.state = {
      text: ''
    };

    this.inputRef = React.createRef();

    this.updateText = this.updateText.bind(this);
    this.sendTextMessage = this.sendTextMessage.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

  }

  shouldComponentUpdate ( nextProps, nextState ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.props.secured !== nextProps.secured ||
      this.state.text !== nextState.text ||
      this.props.settingsSend !== nextProps.settingsSend
    );
  }

  updateText ( event ) {
    this.setState({
      text: event.target.value
    });
  }

  handleKeyDown ( event ) {
    if (
      this.props.settingsSend &&
      event.keyCode === 13 &&
      ! event.shiftKey
    ) {
      event.preventDefault();
      this.sendTextMessage();
    }
  }

  sendTextMessage () {
    if (
      this.state.text.length > 0 &&
      this.props.secured
    ) {

      try {
        this.inputRef.current.focus();
      } catch ( error ) {
        console.log(error);
      }

      this.props.sendTextMessage(this.state.text);
      this.setState({
        text: ''
      });

    }
  }

  render () {
    return(
      <div
        className={this.props.classes.root}
      >
        <TextField
          className={this.props.classes.input}
          id="message"
          inputRef={this.inputRef}
          fullWidth={true}
          variant="outlined"
          multiline={true}
          rows={3}
          label="Message"
          inputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
            autoCapitalize: 'off',
            spellCheck: 'false'
          }}
          disabled={! this.props.secured}
          value={this.state.text}
          onChange={this.updateText}
          onKeyDown={this.handleKeyDown}
        />
        <Button
          className={this.props.classes.button}
          size="large"
          variant="contained"
          color="primary"
          disableElevation={true}
          disabled={ this.state.text.length > 0 && this.props.secured ? false : true }
          startIcon={<SendIcon />}
          aria-label="Send"
          onClick={this.sendTextMessage}
        />
      </div>
    );
  }

}

ChannelForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChannelForm);
