import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Badge } from '@material-ui/core';
import { Input as EnterIcon, Settings as SettingsIcon } from '@material-ui/icons';

const styles = ( theme ) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  list: {
    height: '100%'
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

class DrawerContent extends React.Component {

  constructor ( props ) {

    super(props);

    this.setActiveRoute = this.setActiveRoute.bind(this);

  }

  shouldComponentUpdate ( nextProps ) {
    return(
      this.props.theme !== nextProps.theme ||
      this.props.activeRoute !== nextProps.activeRoute ||
      Object.entries(this.props.routeEvents).sort().toString() !== Object.entries(nextProps.routeEvents).sort().toString() ||
      this.props.channels.toString() !== nextProps.channels.toString()
    );
  }

  setActiveRoute ( event ) {
    this.props.setActiveRoute(event.currentTarget.getAttribute('route'));
  }

  render () {
    return(
      <div
        className={this.props.classes.root}
      >
        <ListItem
          key="enter"
          route="enter"
          button={true}
          aria-label="Enter a channel"
          onClick={this.setActiveRoute}
        >
          <ListItemIcon>
            <EnterIcon />
          </ListItemIcon>
          <ListItemText
            primary="Enter a channel"
          />
        </ListItem>
        <List
          className={this.props.classes.list}
        >
          {
            this.props.channels.map(( item ) => 
              <ListItem
                key={item.route}
                route={item.route}
                button={true}
                aria-label={'Channel ' + item.channel}
                onClick={this.setActiveRoute}
                
              >
                <ListItemText
                  primary={'#' + item.channel}
                  disableTypography={true}
                  classes={{
                    root: this.props.classes.text
                  }}
                />
                <Badge
                  badgeContent={this.props.routeEvents[item.route] ? this.props.routeEvents[item.route] : 0}
                  color="secondary"
                  overlap="circle"
                />
              </ListItem>
            )
          }
        </List>
        <ListItem
          key="settings"
          route="settings"
          button={true}
          aria-label="Settings"
          onClick={this.setActiveRoute}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Settings"
          />
        </ListItem>
      </div>
    );
  }

}

DrawerContent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DrawerContent);
